
import decode from "jwt-decode";
const loggedIn = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};


const getToken = () => {
  // Retrieves the user token from localStorage
  return localStorage.getItem("token");
};

const setToken = (token) => {
  localStorage.setItem("token", token);
}
const getProfile = () => {
  // Using jwt-decode npm package to decode the token
  return decode(getToken());
};

const logout = () => {
  // Clear user token and profile data from localStorage
  localStorage.removeItem("token");
};


export { getToken, setToken, getProfile, loggedIn, logout };
